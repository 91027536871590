import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-input-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './input-select.component.html',
  styleUrl: './input-select.component.css',
})
export class InputSelectComponent implements OnInit {
  @Input() id!: string;
  @Input() label: string = '';
  @Input() controlName!: string;
  @Input() required: boolean = false;
  @Input() form!: FormGroup;
  @Input() placeholder: string = '';
  @Input() options: { value: string | number; label: string }[] = [];

  control!: FormControl;
  showDropdown: boolean = false;
  selectedLabel: string = '';

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control = this.form.get(this.controlName) as FormControl;
    this.selectedLabel =
      this.options.find(opt => opt.value === this.control.value)?.label || '';
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  selectOption(option: { value: string; label: string }): void {
    this.control.setValue(option.value);
    this.selectedLabel = option.label;
    this.showDropdown = false;
  }

  clearSelection(): void {
    this.control.setValue(null);
    this.selectedLabel = '';
    this.showDropdown = false;
  }

  onBlur(): void {
    setTimeout(() => {
      this.showDropdown = false;
    }, 100);
  }
}
