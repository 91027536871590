<div class="flex flex-col gap-0.5 pb-2" [formGroup]="form">
  <label [for]="id" class="font-medium text-tertiary pt-2 pl-1.5">
    {{ label }}
    <span *ngIf="required && label" class="text-red-600">*</span>
  </label>

  <div class="relative rounded-md bg-gray-100">
    <input
      [id]="id"
      type="text"
      class="w-full h-12 rounded-md outline-none p-3 text-base font-extralight text-tertiary cursor-pointer appearance-none"
      [placeholder]="placeholder"
      [value]="selectedLabel"
      (blur)="onBlur()"
      (click)="toggleDropdown()"
      readonly />

    <ul
      *ngIf="showDropdown"
      class="absolute z-10 bg-white border rounded-md mt-1 max-h-48 overflow-auto w-full text-base"
      [ngStyle]="{ top: '100%', left: '0' }">
      <li
        *ngIf="control.value !== null"
        (mousedown)="clearSelection()"
        class="px-4 py-2 cursor-pointer hover:bg-gray-200 text-red-600">
        {{ 'LIMPAR_SELECAO' | translate }}
      </li>
      <li
        *ngFor="let option of options"
        (mousedown)="selectOption(option)"
        class="px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center">
        <span
          *ngIf="option.color"
          [style.background-color]="option.color"
          class="status-circle mr-2"></span>
        {{ option.label }}
      </li>
      <li *ngIf="options.length === 0" class="px-4 py-2 text-gray-500">
        {{ 'NENHUMA_OPCAO_ECONTRADA' | translate }}
      </li>
    </ul>

    <img
      src="assets/icons/arrow-down.svg"
      alt="Filter Icon"
      width="23"
      height="23"
      class="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none" />
  </div>

  <div *ngIf="control.invalid && control.touched" class="text-red-600 text-sm">
    <app-errors [formcontrolname]="controlName" [form]="form"></app-errors>
  </div>
</div>
